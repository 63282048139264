import React from "react";

import { Container, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import spacers from "../styling/spacers";
import colors from "../styling/colors";

interface LayoutProps {
	id?: string;
	background?: string;
	children: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
	root: {
		minHeight: "70em",
		display: "flex",
		flexFlow: "column nowrap",
		justifyContent: "center",
		alignItems: "center",
		maxWidth: "none",
		paddingTop: spacers.LARGE,
		paddingBottom: spacers.LARGE,
		"& > div:first-child": {
			display: "flex",
			flexFlow: "column nowrap",
			justifyContent: "space-around",
			alignItems: "center",
			minHeight: "50em"
		},
		position: "relative",
		[theme.breakpoints.down("xs")]: {
			paddingLeft: "32px",
			paddingRight: "32px"
		},
		overflowY: "hidden",
		overflowX: "hidden"
	},
	box: {
		maxWidth: "79.25em"
	}
}));

const SectionLayout: React.FC<LayoutProps> = ({ id, background, children }) => {
	const classes = useStyles();
	const fontColor =
		background === undefined ? colors.BLACK : background === colors.BACKGROUND_GRAY ? colors.BLACK : colors.WHITE;

	return (
		<Container
			id={id}
			className={classes.root}
			style={{ background: background, color: fontColor, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}
		>
			<Box className={classes.box}>{children}</Box>
		</Container>
	);
};

export default SectionLayout;
