import colors from "./colors";

const fontSizes = {
	XLARGE: "3.57em", // 50px
	LARGE: "24px", // !24px
	MEDIUM: "1.29em", // 18px
	NORMAL: "1.143em", // 16px
	SMALL: "13px" // !13px
};

const fontWeight = {
	BOLD: 700,
	NORMAL: 400,
	THIN: 100
};

const lineHeight = {
	TALL: "150%",
	MEDIUM: "120%",
	NORMAL: "120%",
	SHORT: "1.53em" // 24px
};

interface FontStyle {
	fontSize: string;
	color?: string;
	fontWeight: number;
	lineHeight?: string;
	letterSpacing?: string;
}

const fontStyles: { [styleProperty: string]: FontStyle } = {
	TITLE: {
		fontSize: fontSizes.XLARGE,
		fontWeight: fontWeight.BOLD,
		lineHeight: lineHeight.MEDIUM
	},
	SUBTITLE: {
		fontSize: fontSizes.MEDIUM,
		fontWeight: fontWeight.NORMAL,
		lineHeight: lineHeight.TALL
	},
	TEXT: {
		fontSize: fontSizes.NORMAL,
		fontWeight: fontWeight.NORMAL,
		lineHeight: lineHeight.TALL
	},
	NAV_LINKS: {
		fontSize: fontSizes.NORMAL,
		color: colors.BLACK,
		fontWeight: fontWeight.BOLD,
		letterSpacing: "-0.02em"
	},
	LARGE_TEXT: {
		fontSize: fontSizes.LARGE,
		fontWeight: fontWeight.NORMAL,
		lineHeight: lineHeight.SHORT
	},
	SMALL_TEXT: {
		fontSize: fontSizes.SMALL,
		fontWeight: fontWeight.NORMAL,
		lineHeight: lineHeight.SHORT
	},
	FOOTER_TITLE: {
		fontSize: fontSizes.NORMAL,
		color: colors.LIGHT_GRAY,
		fontWeight: fontWeight.BOLD,
		lineHeight: lineHeight.TALL
	},
	FOOTER_TEXT: {
		fontSize: fontSizes.NORMAL,
		color: colors.LIGHT_GRAY,
		fontWeight: fontWeight.NORMAL,
		lineHeight: lineHeight.TALL
	}
};

export { fontStyles, fontSizes, fontWeight, lineHeight };
