import React from "react";

import { Container, Grid, Link, useMediaQuery, IconButton, Typography, Divider } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { fontStyles } from "../styling/font-styles";
import colors from "../styling/colors";
import spacers from "../styling/spacers";
import HyperflyerIcon from "../../images/logos/white-icon.svg";
import HyperflyerLogo from "../../images/logos/white-logo.svg";
import FacebookIcon from "../../images/social-media/facebook.svg";
import LinkedInIcon from "../../images/social-media/linkedin.svg";
import TwitterIcon from "../../images/social-media/twitter.svg";
import InstagramIcon from "../../images/social-media/instagram.svg";
import Map from "../../images/map.svg";

const useStyles = makeStyles(theme => ({
	container: {
		backgroundColor: colors.BLACK,
		paddingTop: spacers.XLARGE,
		paddingBottom: spacers.XLARGE,
		display: "flex",
		justifyContent: "center",
		maxWidth: "none",
		"& > div:first-child": {
			maxWidth: "90em"
		},
		overflowX: "hidden"
	},
	logoSpacer: {
		margin: spacers.SMALL
	},
	iconSpacer: {
		margin: spacers.XSMALL
	},
	icons: {
		color: colors.LIGHT_GRAY,
		margin: "0 3px",
		"&:hover": {
			backgroundColor: "transparent"
		}
	},
	image: {
		[theme.breakpoints.down("xs")]: {
			margin: "0 auto"
		}
	},
	label: {
		...fontStyles.FOOTER_TITLE,
		marginBottom: spacers.XSMALL,
		[theme.breakpoints.down("xs")]: {
			textAlign: "center"
		}
	},
	text: {
		...fontStyles.FOOTER_TEXT,
		[theme.breakpoints.down("xs")]: {
			textAlign: "center"
		}
	},
	linkDivider: {
		width: "0%",
		[theme.breakpoints.down("sm")]: {
			width: "100%",
			marginTop: spacers.MEDIUM,
			marginBottom: spacers.MEDIUM
		}
	},
	contactDivider: {
		width: "0%",
		[theme.breakpoints.down("xs")]: {
			width: "100%",
			marginBottom: spacers.MEDIUM
		}
	},
	textSpacer: {
		marginTop: spacers.MEDIUM
	}
}));

const Footer: React.FC = () => {
	const classes = useStyles();
	const isTablet = useMediaQuery("(max-width:959px)");
	const isPhone = useMediaQuery("(max-width:599px)");
	const columnSpacing = isPhone ? 12 : isTablet ? 6 : 4;
	const linkSpacing = isTablet ? 12 : 4;
	const companyBlogSpacing = isPhone ? 12 : isTablet ? 3 : 6;
	const businessSpacing = isPhone ? 12 : isTablet ? 3 : 12;
	const linkAlign = isPhone ? "center" : "flex-start";
	const gridSpacing = isPhone ? 0 : 3;

	return (
		<Container disableGutters className={classes.container}>
			<Grid container direction="row" spacing={5}>
				{/* right column with logo and social media icons */}
				<Grid item container direction="column" justify="center" alignItems="center" xs={columnSpacing}>
					<Grid item>
						<HyperflyerIcon />
					</Grid>
					<Grid item className={classes.logoSpacer}>
						<HyperflyerLogo />
					</Grid>
					<Grid item container wrap="nowrap" justify="center" className={classes.iconSpacer}>
						<IconButton
							href="https://www.linkedin.com/company/hyperflyer/"
							aria-label="Hyperflyer LinkedIn"
							className={classes.icons}
						>
							<LinkedInIcon />
						</IconButton>
						<IconButton
							href="https://www.facebook.com/hyperflyer"
							aria-label="Hyperflyer Facebook"
							className={classes.icons}
						>
							<FacebookIcon />
						</IconButton>
						<IconButton
							href="https://www.instagram.com/hyperflyerapp/"
							aria-label="Hyperflyer Instagram"
							className={classes.icons}
						>
							<InstagramIcon />
						</IconButton>
						<IconButton
							href="https://twitter.com/hyperflyer_main"
							aria-label="Hyperflyer Twitter"
							className={classes.icons}
						>
							<TwitterIcon />
						</IconButton>
					</Grid>
				</Grid>

				{/* middle column with address and email */}
				<Grid item container direction="column" wrap="nowrap" spacing={gridSpacing} xs={columnSpacing}>
					<Divider className={classes.contactDivider} />
					<Grid item className={classes.image}>
						<Map />
					</Grid>
					<Grid item className={classes.textSpacer}>
						<Typography className={classes.label}>Address</Typography>
						<Typography className={classes.text}>
							Two Embarcadero Center, 8th <br /> Floor, San Francisco, CA 94111
						</Typography>
					</Grid>
					<Grid item className={classes.textSpacer}>
						<Typography className={classes.label}>Email</Typography>
						<Link href="mailto:contact@hyperflyer.com">
							<Typography className={classes.text}>contact@hyperflyer.com</Typography>
						</Link>
					</Grid>
				</Grid>

				{/* left column with links */}
				<Grid item container justify="center" xs={linkSpacing} spacing={gridSpacing}>
					<Divider className={classes.linkDivider} />
					<Grid
						item
						container
						direction="column"
						xs={companyBlogSpacing}
						alignItems={linkAlign}
						className={classes.textSpacer}
					>
						<Typography className={classes.label}>Company</Typography>
						<Link href="https://www.hyperflyer.com/about-us/" className={classes.text}>
							About Us
						</Link>
						<Link href="https://www.hyperflyer.com/contact-us/" className={classes.text}>
							Contact Us
						</Link>
						<Link href="https://www.hyperflyer.com/privacy-policy/" className={classes.text}>
							Privacy Policy
						</Link>
						<Link href="https://www.hyperflyer.com/terms-of-services/" className={classes.text}>
							Terms of Service
						</Link>
					</Grid>
					<Grid
						item
						container
						direction="column"
						xs={companyBlogSpacing}
						alignItems={linkAlign}
						className={classes.textSpacer}
					>
						<Typography className={classes.label}>Blog</Typography>
						<Link href="https://www.hyperflyer.com/the-community-table/" className={classes.text}>
							Home
						</Link>
						<Link href="https://www.hyperflyer.com/category/news/" className={classes.text}>
							News
						</Link>
						<Link href="https://www.hyperflyer.com/category/restaurant/" className={classes.text}>
							Restaurants
						</Link>
						<Link href="https://www.hyperflyer.com/category/culture/" className={classes.text}>
							Culture
						</Link>
					</Grid>
					<Grid
						item
						container
						direction="column"
						xs={businessSpacing}
						alignItems={linkAlign}
						className={classes.textSpacer}
					>
						<Typography className={classes.label}>For Business</Typography>
						<Link href="https://www.hyperflyer.com/business/support/" className={classes.text}>
							Support
						</Link>
					</Grid>
				</Grid>
			</Grid>
		</Container>
	);
};

export default Footer;
