import React, { ButtonHTMLAttributes } from "react";
import { TextTransformProperty } from "csstype";
import { Button } from "@material-ui/core";
import { trackButtonClickEvent } from "./trackButtonClickEvent";
import { fontWeight, fontSizes } from "../styling/font-styles";
import colors from "../styling/colors";
import spacers from "../styling/spacers";


// props to pass through props when creating/using button in sections
interface ButtonProps {
	href?: string;
	type?: ButtonHTMLAttributes<HTMLButtonElement>["type"];
	onClick?: ButtonHTMLAttributes<HTMLButtonElement>["onClick"];
	children?: React.ReactNode;
}

// props to be used for any configurations below in this file
interface ButtonConfig {
	fontSize: string;
	color: string;
	fontWeight: number;
	padding: string;
	marginTop?: string;
	marginLeft?: string;
	marginRight?: string;
	borderRadius: string;
	background: string;
	textTransform: TextTransformProperty;
	textDecoration: string;
}

// configurations for different buttons; add/remove below as necessary

const NavButtonConfig: ButtonConfig = {
	fontSize: fontSizes.SMALL,
	color: colors.WHITE,
	fontWeight: fontWeight.BOLD,
	padding: "11px 34px",
	borderRadius: "100px",
	background: colors.BUTTON,
	textTransform: "uppercase",
	textDecoration: "none"
};

const MainButtonConfig: ButtonConfig = {
	fontSize: fontSizes.SMALL,
	color: colors.WHITE,
	fontWeight: fontWeight.BOLD,
	padding: "1.55em 3.1em",
	marginTop: spacers.LARGE,
	borderRadius: "50px",
	background: colors.BUTTON,
	textTransform: "uppercase",
	textDecoration: "none"
};

const FormButtonConfig: ButtonConfig = {
	fontSize: fontSizes.SMALL,
	color: colors.WHITE,
	fontWeight: fontWeight.BOLD,
	padding: "1.55em 3.1em",
	borderRadius: "50px",
	background: colors.BUTTON,
	textTransform: "uppercase",
	textDecoration: "none",
	marginLeft: "30px",
	marginRight: "30px"
};

// higher-order component to return JSX element when creating button
const MakeButton = (config: ButtonConfig) => ({ href, type, children }) => {
	const onClickHandler = () => {
		return trackButtonClickEvent();
	}
	return (
		// <a href={`/${href}`} style={{ textDecoration: "none" }}>
		<Button href={href} type={type || "button"} onClick={onClickHandler()} style={{ ...config }}>
			{children}
		</Button>
		// </a> *
	);
};

// creating buttons for export
const MainButton: React.FC<ButtonProps> = MakeButton(MainButtonConfig);
const NavButton: React.FC<ButtonProps> = MakeButton(NavButtonConfig);
const FormButton: React.FC<ButtonProps> = MakeButton(FormButtonConfig);

export { MainButton, NavButton, FormButton };
