import React from "react";

import "../../fonts/fonts.css";

import { Typography, TypographyVariant } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { fontStyles } from "../styling/font-styles";

const useStyles = makeStyles(theme => ({
	title: {
		...fontStyles.TITLE,
		fontFamily: "'Roboto Slab', serif",
		[theme.breakpoints.down("xs")]: {
			fontSize: "2.75em"
		}
	}
}));

interface TitleProps {
	variant: TypographyVariant;
	children: React.ReactNode;
}

const Title: React.FC<TitleProps> = ({ variant, children }) => {
	const classes = useStyles();
	return (
		<Typography align="center" variant={variant || "inherit"} className={classes.title}>
			{children}
		</Typography>
	);
};

export default Title;
