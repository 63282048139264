import React from "react";

import { Container } from "@material-ui/core";

import colors from "../styling/colors";
import NavBar from "./navbar";
import Footer from "./footer";

interface LayoutProps {
	children: React.ReactNode;
}

const MainLayout: React.FC<LayoutProps> = ({ children }) => {
	if (typeof window !== "undefined") {
		require("smooth-scroll")('a[href*="#"]');
	}

	return (
		<>
			<Container
				maxWidth="lg"
				disableGutters
				style={{ maxWidth: "none", background: colors.WHITE, overflowX: "hidden" }}
			>
				<NavBar />

				{children}
			</Container>
			<Footer />
		</>
	);
};

export default MainLayout;
