// file used for all colors in site
const colors = {
	WHITE: "#FFFFFF",
	LIGHT_GRAY: "#F0F0F0",
	BACKGROUND_GRAY: "#E5E5E5",
	MEDIUM_GRAY: "#969696",
	BLACK: "#333132",
	DARK_GRAY: "#636363",
	BUTTON: "linear-gradient(180deg, #78DDC9 0%, #00B09A 100%)",
	GRADIENT_BACKGROUND: "linear-gradient(180deg, #6BB3EC 0%, #93F7E7 100%)"
};

export default colors;
