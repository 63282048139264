import React, { useState, useEffect, useCallback, useRef } from "react";

import { Grid, Link, AppBar, Toolbar, Drawer, List, ListItem, IconButton, useMediaQuery } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";

import { NavButton } from "../buttons/buttons";
import { fontStyles } from "../styling/font-styles";
import colors from "../styling/colors";
import spacers from "../styling/spacers";
import WhiteHyperflyerLogo from "../../images/logos/white-nav-logo.svg";
import BlackHyperflyerLogo from "../../images/logos/nav-logo.svg";

const useStyles = makeStyles(theme => ({
	appBar: {
		background: colors.WHITE,
		boxShadow: "none",
		height: "130px",
		display: "flex",
		justifyContent: "center",
		borderRadius: "0px 0px 10px 10px",
		overflowX: "hidden",
		[theme.breakpoints.down("xs")]: {
			height: "80px"
		}
	},
	logo: {
		"&:hover": {
			backgroundColor: "transparent"
		}
	},
	navItems: {
		maxWidth: "79.25em",
		margin: "0 auto"
	},
	link: {
		...fontStyles.NAV_LINKS
	},
	drawer: {
		width: "20em"
	},
	closeButton: {
		display: "flex",
		justifyContent: "flex-end"
	},
	listItem: {
		padding: spacers.SMALL
	}
}));

const NavBar: React.FC = () => {
	const classes = useStyles();
	const isTablet = useMediaQuery("(max-width:959px)");
	const navRef = useRef(null);
	const [isLoading, setIsLoading] = useState(true);
	const [isOpen, setIsOpen] = useState(false);
	const [mainNav, setMainNav] = useState(true);

	const navIcon = mainNav ? <WhiteHyperflyerLogo /> : <BlackHyperflyerLogo />;
	const navColor = mainNav ? colors.WHITE : colors.BLACK;
	const hamburgerColor = mainNav ? colors.WHITE : colors.BLACK;
	const navBackground = mainNav ? "transparent" : colors.WHITE;

	const handleScroll = useCallback(() => {
		const navHeight = navRef.current.offsetHeight;
		const changeNav = window.scrollY > 980 - navHeight;
		if (changeNav) {
			setMainNav(false);
		} else {
			setMainNav(true);
		}
	}, []);

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);
		setIsLoading(false);

		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	const hamburgerNav = (
		<>
			<AppBar ref={navRef} className={classes.appBar} style={{ background: navBackground }}>
				<Toolbar>
					<Grid container wrap="nowrap" alignItems="flex-end" className={classes.navItems}>
						<Grid item>
							<IconButton href="https://www.hyperflyer.com/" aria-label="Hyperflyer Home Page" className={classes.logo}>
								{navIcon}
							</IconButton>
						</Grid>

						<Grid item container wrap="nowrap" justify="flex-end" alignItems="center" spacing={3}>
							<Grid item>
								<IconButton onClick={() => setIsOpen(true)}>
									<MenuIcon style={{ color: hamburgerColor }} />
								</IconButton>
							</Grid>
						</Grid>
					</Grid>
				</Toolbar>
			</AppBar>
			<Drawer anchor="right" open={isOpen} onClose={() => setIsOpen(false)}>
				<List className={classes.drawer}>
					<ListItem divider dense className={classes.closeButton}>
						<IconButton onClick={() => setIsOpen(false)}>
							<CloseIcon />
						</IconButton>
					</ListItem>
					<ListItem divider className={classes.listItem}>
						<Link href="https://www.hyperflyer.com/about-us/" underline="none" className={classes.link}>
							About Us
						</Link>
					</ListItem>
					<ListItem>
						<Grid item className={classes.listItem}>
							<NavButton href="https://www.hyperflyer.com/contact-us/">Join Hyperflyer</NavButton>
						</Grid>
					</ListItem>
				</List>
			</Drawer>
		</>
	);

	const fullNav = (
		<AppBar ref={navRef} className={classes.appBar} style={{ background: navBackground }}>
			<Toolbar>
				<Grid container wrap="nowrap" alignItems="center" className={classes.navItems}>
					<Grid item>
						<IconButton href="https://www.hyperflyer.com/" aria-label="Hyperflyer Home Page" className={classes.logo}>
							{navIcon}
						</IconButton>
					</Grid>

					<Grid item container wrap="nowrap" justify="flex-end" alignItems="center" spacing={3}>
						<Grid item>
							<Link
								href="https://www.hyperflyer.com/about-us/"
								underline="none"
								className={classes.link}
								style={{ color: navColor }}
							>
								About Us
							</Link>
						</Grid>
						<Grid item>
							<NavButton href="https://www.hyperflyer.com/contact-us/">Join Hyperflyer</NavButton>
						</Grid>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);

	return isLoading ? null : isTablet ? hamburgerNav : fullNav;
};

export default NavBar;
