import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

export function trackButtonClickEvent() {
    return (e) => {
        trackCustomEvent({
            category: e.target.innerText,
            action: "Click",
            label: e.target.parentElement.href,
        });
    };
}