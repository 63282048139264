import React from "react";

import { Typography, TypographyVariant } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { fontStyles } from "../styling/font-styles";
import spacers from "../styling/spacers";

const useStyles = makeStyles(theme => ({
	subtitle: {
		marginTop: spacers.SMALL,
		...fontStyles.SUBTITLE,
		[theme.breakpoints.down("xs")]: {
			fontSize: "1.2em"
		}
	}
}));

interface SubtitleProps {
	variant: TypographyVariant;
	children: React.ReactNode;
}

const SubTitle: React.FC<SubtitleProps> = ({ variant, children }) => {
	const classes = useStyles();
	return (
		<Typography align="center" variant={variant || "inherit"} className={classes.subtitle}>
			{children}
		</Typography>
	);
};

export default SubTitle;
